var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8d7c158a937bde3d00569aa86fc8448da09b5bca"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || 'null',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.0,
    tracesSampler: (samplingContext) => {
        if (samplingContext.transactionContext.op === 'payment') {
            return 1.0;
        }

        return 0.0;
    },
    defaultIntegrations: false,
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    ignoreErrors: [
        /Non-Error/g,
        /i18next/g,
        /Unexpected token '<'/g,
        /AxiosError/g,
        /Network Error/g,
        /Script.*load failed/g,
    ],
    maxBreadcrumbs: 10,
    autoSessionTracking: true,
    sendClientReports: false,
    replaysSessionSampleRate: 1,
});
